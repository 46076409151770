import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';

import { Container, Icon, NewsLetter } from '@/components';
import { fetchFilteredBlogItems } from '@/services/supabase';
import type { IBlogItemDTO } from '@/types/blogInterfaces';
import { getFlag } from '@/utils';

import { compareMonths, formatDateToMonthYearFormat } from './blog.utils';
import { BlogCard, BlogNavBar } from './parts';

interface IBlogProps {
  blogItems: IBlogItemDTO[];
}

export const Blog: FC<IBlogProps> = ({ blogItems }) => {
  const initialRender = useRef(true);

  const router = useRouter();

  const { t } = useTranslation();
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState(10);

  const [blogItemsList, setBlogItemsList] = useState(blogItems);

  const fetchBlogItemsList = async (limit: number) => {
    const params: any = {
      pagination: {
        start: 0,
        limit,
      },
      sort: { field: 'publishedAt', order: 'desc' },
      locale: router.locale as string,
    };

    if (router.query.country) {
      params.filter = {
        field: 'Country',
        operator: '$eq',
        value: router.query.country,
      };
    }

    const items = await fetchFilteredBlogItems(params);

    setBlogItemsList(items.data);
    setTotal(items.meta.pagination.total);
  };

  const loadMore = async (): Promise<void> => {
    setLoading(true);
    setOffset((prevOffset) => prevOffset + 10);
    setLoading(false);
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    setOffset(10);
    fetchBlogItemsList(10);
  }, [router.query]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchBlogItemsList(offset);
  }, [offset]);

  return (
    <>
      <div className="relative pb-[64px] lg:pb-[96px] lg:pt-[48px] lg:before:absolute lg:before:inset-x-4 lg:before:inset-y-0 lg:before:rounded-t-[1rem] lg:before:bg-blue-50 xl:pt-[64px]">
        <Container className="flex flex-col gap-8 md:gap-12 lg:grid lg:grid-cols-[180px_1fr] xl:grid-cols-[227px_1fr] xl:gap-[64px]">
          <div className="z-20 flex h-[56px] flex-col overflow-x-auto md:overflow-x-hidden lg:h-full">
            <BlogNavBar />
          </div>
          <div className="z-10 flex flex-col gap-6 before:absolute before:inset-x-0 before:bottom-0 before:top-[56px] before:bg-blue-50 md:before:rounded-[1rem] lg:before:inset-x-4">
            <div className="z-10 flex flex-col sm:flex-row sm:gap-2 md:mb-3">
              {router.query.country ? (
                <>
                  <h2 className="text-[32px] leading-[40px] tracking-[-0.64px] text-blue-900 md:text-[40px] md:leading-[48px]">
                    {t('blog.title.text_3')}
                  </h2>

                  <div className="flex items-baseline gap-2">
                    <h2 className="text-[32px] leading-[40px] tracking-[-0.64px] text-blue-900 md:text-[40px] md:leading-[48px]">
                      {t('blog.description.text_4')}
                    </h2>
                    <Image
                      src={getFlag(router.query.country as string)}
                      alt={router.query.country as string}
                      className="h-[18px] w-6 rounded sm:h-6 sm:w-8"
                      width={8}
                      height={6}
                      unoptimized
                    />
                    <h2 className="text-[32px] leading-[40px] tracking-[-0.64px] text-blue-900 md:text-[40px] md:leading-[48px]">
                      {t(
                        `database.countries.${regionNames.of(
                          router.query.country as string,
                        )}`,
                      )}
                    </h2>
                  </div>
                </>
              ) : (
                <h1 className="text-[32px] leading-[40px] tracking-[-0.64px] text-blue-900 md:text-[40px] md:leading-[48px]">
                  {t('pages.blog.h1')}
                </h1>
              )}
            </div>

            <div className="flex flex-col gap-3 sm:gap-4">
              {blogItemsList.map((item, index, arr) => {
                const { currentDate, isEqual } = compareMonths(
                  item.attributes.publishedAt,
                  index,
                  arr[index - 1]?.attributes.publishedAt,
                );
                return (
                  <Fragment key={index}>
                    {!isEqual && (
                      <div className="z-10 flex justify-end">
                        <time className="body3 text-grey-500">
                          {formatDateToMonthYearFormat(
                            new Date(currentDate).toISOString(),
                          )}
                        </time>
                      </div>
                    )}
                    <Link href={`/blog/${item.attributes.slug}`}>
                      <BlogCard item={item.attributes} />
                    </Link>
                  </Fragment>
                );
              })}
            </div>
            {total >= 10 && (
              <button
                type="button"
                onClick={loadMore}
                disabled={loading}
                className="btn-blue-states z-10 mx-auto flex w-[240px] items-center justify-center gap-1 rounded-full px-8 py-[21px] text-[15px] leading-[20px]"
              >
                {total < offset ? (
                  t('global.you_reach_the_end')
                ) : (
                  <>
                    {t('global.load_more')}
                    <Icon
                      className="h-4 w-4 text-transparent"
                      name="arrowRightWhite"
                    />
                  </>
                )}
              </button>
            )}
          </div>
        </Container>
      </div>

      <NewsLetter />
    </>
  );
};
