/* eslint-disable react/jsx-no-duplicate-props */
import { marked } from 'marked';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import {
  BankCard,
  Container,
  NoDepositFound,
  OtherDepositTable,
} from '@/components';
import { useApi } from '@/contexts/ApiContext';
import { useCmsContent } from '@/contexts/CmsContentContext';
import useFavouriteDeposits from '@/hooks/useFavouriteDeposits';
import useIsInViewport from '@/hooks/useIsInViewport';
import MainBg from '@/public/assets/pics/bg.png';
import { MapDepositsFromServer } from '@/services/supabase';
import { useAuthStore } from '@/store';
import type { IDepositCardProps } from '@/types';

import { DepositDetails, DepositHero, DepositHeroBar } from './parts';

export const Deposit: FC<IDepositCardProps> = ({
  depositCard,
  similarDepositsAvailableOnline,
  similarDepositsAvailableOnlineMeta,
  otherDeposits,
  isScrolledUp = false,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const heroRef = useRef(null);
  const isInViewport = useIsInViewport(heroRef);
  const locale: string = router.locale || 'en';
  const [loading, setLoading] = useState(false);
  const [similarDeposits, setSimilarDeposits] = useState(
    similarDepositsAvailableOnline,
  );
  const [similarDepositsMeta, setSimilarDepositsMeta] = useState(
    similarDepositsAvailableOnlineMeta,
  );

  const { isTestUser } = useAuthStore((state) => state);
  const { seoContent } = useCmsContent();
  const api = useApi();

  const loadMore = async (): Promise<void> => {
    setLoading(true);

    const { data: newDeposits, meta } =
      await api.getSimilarDepositsAvailableOnline(
        locale || 'en',
        depositCard.uuid,
        +similarDepositsMeta.page + 1,
        isTestUser || false,
      );

    // Convert the data to the appropriate format
    const depositCards = newDeposits?.map((deposit: any) =>
      MapDepositsFromServer(deposit),
    )!;

    setSimilarDepositsMeta(meta);

    // Append the new deposits to the existing ones
    setSimilarDeposits((prevDeposits) => [...prevDeposits, ...depositCards!]);
    setLoading(false);
  };

  const { handleFavourite, checkIsFavorite } = useFavouriteDeposits();

  useEffect(() => {
    setSimilarDeposits(similarDepositsAvailableOnline);
  }, [locale]);

  return (
    <div className="relative z-10 flex h-full flex-col rounded-lg">
      <div ref={heroRef} className="relative">
        <div className="absolute inset-0 overflow-hidden xl:inset-x-4 xl:rounded-2xl">
          <Image
            src={MainBg}
            alt="Main Bg"
            className="object-cover"
            loading="lazy"
            fill
          />
        </div>
        <Container className="mb-12">
          {depositCard ? (
            <div className="mx-[-4px] mt-6 md:mx-0">
              <DepositHero
                bank={depositCard}
                onBackButtonClick={() => router.back()}
                isFavourite={checkIsFavorite(depositCard)}
                handleFavourite={() => handleFavourite(depositCard)}
              />
            </div>
          ) : (
            <NoDepositFound />
          )}
        </Container>
      </div>
      {!isInViewport && (
        <DepositHeroBar
          isScrolledUp={isScrolledUp}
          bank={depositCard}
          isFavourite={checkIsFavorite(depositCard)}
          handleFavourite={() => handleFavourite(depositCard)}
        />
      )}

      <Container className="mt-[43px] md:mt-12">
        <DepositDetails bank={depositCard} />
      </Container>

      <Container className="mb-[64px] mt-12">
        {otherDeposits.length > 0 && (
          <div className="flex flex-col">
            <h3 className="font-inter text-2xl font-semibold leading-[28px] tracking-[0.2px]">
              {t('deposits.similar_deposit_text')}
            </h3>
            <OtherDepositTable bankInfo={otherDeposits} />
          </div>
        )}
      </Container>

      <div className="relative before:absolute before:inset-0 before:z-[-1] before:bg-blue-900 lg:before:rounded-2xl xl:before:inset-x-4">
        <Container className="mb-[96px]  mt-[49px] lg:mt-[64px]">
          <div className="mb-9 flex">
            <p className="h4 font-semibold tracking-[0.2px] text-white">
              {t('deposits.similar_available_online')}
            </p>
          </div>
          <div className="flex flex-col gap-7">
            {similarDeposits.map((bank) => (
              <BankCard
                key={bank.productIndex}
                bank={bank}
                isFavourite={checkIsFavorite(bank)}
                handleFavourite={() => handleFavourite(bank)}
              />
            ))}
            {Math.ceil(similarDepositsMeta.total / similarDepositsMeta.count) >
              similarDepositsMeta.page && (
              <button
                type="button"
                onClick={loadMore}
                disabled={loading}
                className="btn-blue-states mx-auto min-w-[148px] rounded-full px-6 py-[18px] disabled:bg-grey-300"
              >
                {loading ? t('global.loading') : t('global.show_more')}
              </button>
            )}
          </div>
        </Container>
      </div>
      {seoContent?.mainText && (
        <Container className="my-12 max-w-[850px] px-5 lg:px-0">
          <div
            dangerouslySetInnerHTML={{
              __html: marked(seoContent.mainText),
            }}
            className="markdown-article flex flex-col gap-4 md:mx-[34px] lg:mx-auto lg:flex lg:w-full lg:max-w-[849px] lg:flex-col"
          />
        </Container>
      )}
    </div>
  );
};
