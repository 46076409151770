import { TrashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { Icon } from '@/components';
import { cn } from '@/utils';

interface IDeletePopupProps {
  handleClose: () => void;
  handleDelete: () => void;
  className?: string;
}

export const DeletePopup: FC<IDeletePopupProps> = ({
  handleClose,
  handleDelete,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'h-36 w-[270px] rounded-xl bg-white px-5 py-4 shadow-2xl',
        className,
      )}
    >
      <div className="flex justify-between">
        <p className="font-semibold text-darkColor">
          {t('favorites.delete_favourites')}
        </p>
        <div onClick={handleClose}>
          <Icon
            name="closeIcon"
            className="h-6 w-6 cursor-pointer text-transparent"
          />
        </div>
      </div>
      <div className="mt-3 flex justify-between">
        <button
          type="button"
          className="rounded-lg bg-[#EBF0FF] px-5 py-3 font-semibold text-blueColor hover:bg-[#EBF0FF]/80 hover:text-blueColor/60"
          onClick={handleClose}
        >
          {t('global.cancel')}
        </button>
        <button
          type="button"
          className="btn-blue-states flex content-center items-center justify-center gap-2 rounded-lg bg-blueColor px-5 py-3 font-semibold"
          onClick={handleDelete}
        >
          <TrashIcon className="h-5 w-5 text-white" />
          <p className="text-white">{t('global.delete')}</p>
        </button>
      </div>
    </div>
  );
};
