import { useUser } from '@supabase/auth-helpers-react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next-translate-routes/router';
import type { Dispatch, FC, SetStateAction } from 'react';
import React from 'react';

import { Icon, SmartLink } from '@/components';
import { useAuthModalsControl, useAuthStore } from '@/store';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { useShowModal } from '@/usecases/modals';
import { analyticServices, cn } from '@/utils';

interface IConfirmPopupFooterProps {
  source: string;
  setConfirmPopup: Dispatch<SetStateAction<boolean>>;
}

export const ConfirmPopupFooter: FC<IConfirmPopupFooterProps> = ({
  source,
  setConfirmPopup,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const user = useUser();
  const { isTestUser } = useAuthStore((state) => state);
  const { openSignupModal, setIsDefaultText } = useAuthModalsControl(
    (state) => state,
  );
  const { showQuestionOpenDepositModal } = useShowModal();

  const handleSignUp = () => {
    setConfirmPopup(false);
    openSignupModal();
    setIsDefaultText(false);

    analyticServices.sendEvent({
      action: AnalyticsEvent.clickSignUp,
      label: 'clickSignUp',
    });
  };

  const handleAnaluticsClick = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.visitBankSite,
      category: AnalyticsCategory.deposit,
      label: 'Deposit ID',
    });
    setConfirmPopup(false);
    showQuestionOpenDepositModal({
      productIndex: router.query.productIndex as string,
      isTestUser,
    });
  };
  return (
    <div className="flex flex-col items-center justify-center gap-4 md:flex-row">
      <SmartLink
        href={source}
        analyticsFunction={handleAnaluticsClick}
        className={cn(
          'body2-bold px-6 py-[18px] min-w-full md:min-w-[199px] rounded-full gap-1 flex justify-center items-center',
          'bg-blue-100 text-blue-500 hover:bg-blue-150',
        )}
      >
        {t('deposit_card.button_visit_banks_site')}
        <Icon
          name="rightArrow"
          className="h-3 w-3 rotate-[-45deg] text-transparent"
        />
      </SmartLink>
      {!user ? (
        <button
          type="button"
          className="btn-blue-states flex min-w-full items-center justify-center rounded-full px-8 py-[18px] focus:outline-none md:min-w-[160px]"
          onClick={handleSignUp}
        >
          <p className="font-inter text-[15px] font-bold leading-[20px] text-white">
            {t('header.register')}
          </p>
        </button>
      ) : (
        <button
          type="button"
          className="btn-blue-states flex min-w-full items-center justify-center rounded-full px-8 py-[18px] focus:outline-none md:min-w-[199px]"
          onClick={() => {
            router.push('/fixed-deposits');
          }}
        >
          <p className="font-inter text-[15px] font-bold leading-[20px] text-white">
            {t('deposits.available_online')}
          </p>
        </button>
      )}
    </div>
  );
};
