export const calculateHighestRate = (
  maxInitialDepositRate: number | undefined,
  maxFeaturedDepositRate: number | undefined,
) => {
  if (maxInitialDepositRate && !maxFeaturedDepositRate) {
    return maxInitialDepositRate;
  }
  if (maxFeaturedDepositRate && !maxInitialDepositRate) {
    return maxFeaturedDepositRate;
  }
  return Number(maxInitialDepositRate) > Number(maxFeaturedDepositRate)
    ? maxInitialDepositRate
    : maxFeaturedDepositRate;
};
