import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React from 'react';

import { DepositProductCard } from '@/components';
import type { IBankDepositCard } from '@/types';

interface IDepositDetailsProps {
  bank: IBankDepositCard;
  showTitle?: boolean;
  textStyles?: string;
}

export const DepositDetails: FC<IDepositDetailsProps> = ({
  bank,
  showTitle = true,
  textStyles,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col gap-6 divide-y divide-grey-200 border-b">
      {showTitle && (
        <p className="h4 font-semibold leading-[28px] tracking-[0.2px] text-blue-900">
          {t('global.details')}
        </p>
      )}

      <DepositProductCard
        bank={bank}
        className="divide-y divide-grey-200"
        textStyles={textStyles}
      />
    </div>
  );
};
