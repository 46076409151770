import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';

import { Icon } from '@/components';
import notFoundPng2x from '@/public/assets/pics/404@2x-min.png';
import notFoundWebp2x from '@/public/assets/pics/404@2x-min.webp';
import notFoundPng1x from '@/public/assets/pics/404-min.png';
import notFoundWebp1x from '@/public/assets/pics/404-min.webp';

export const NotFound = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="grid min-h-full place-items-center rounded-3xl bg-blue-50 px-6 py-24 sm:py-32 md:mx-4 md:mt-12 lg:px-8">
      <div className="flex flex-col content-center items-center text-center">
        <picture>
          <source
            srcSet={`${notFoundWebp1x.src} 1x, ${notFoundWebp2x.src} 2x`}
            type="image/webp"
          />
          <source
            srcSet={`${notFoundPng1x.src} 1x, ${notFoundPng2x.src} 2x`}
            type="image/png"
          />
          <img
            src={notFoundPng1x.src}
            width={notFoundPng1x.width}
            height={notFoundPng1x.height}
            alt="404"
          />
        </picture>
        <h5 className="mt-4 text-blue-900">{t('404.title')}</h5>
        <p className="body2 mt-2 text-grey-800">
          {t('404.description1')}
          <br /> {t('404.description2')}.
        </p>
        <div className="mt-8 flex items-center justify-center gap-x-6">
          <Link
            locale={i18n.language}
            href="/"
            className="body2-bold flex content-center items-center gap-2 rounded-md px-3.5 py-2.5 text-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <p className="body2-bold text-blue-500">{t('404.action')}</p>
            <Icon name="rightArrow" className="h-4 w-4 text-transparent" />
          </Link>
        </div>
      </div>
    </div>
  );
};
