const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const formatDateToMonthDateYearFormat = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const formatDateToMonthYearFormat = (dateString: string) => {
  const date = new Date(dateString);
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${year}`;
};

export const compareMonths = (
  currentDate: string,
  index: number,
  prevDate?: string,
) => {
  const date = new Date(currentDate);
  const currentMonth = date.getMonth();

  let prevMonth = currentMonth;

  if (index > 0 && prevDate) {
    prevMonth = new Date(prevDate).getMonth();
  }

  return { currentDate: date, isEqual: currentMonth === prevMonth };
};
