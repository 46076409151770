import { useRouter } from 'next-translate-routes/router';
import { type FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  Container,
  FAQs,
  Hero,
  HowItWorks,
  LogoClouds,
  NewsLetter,
  PickDeposits,
  SearchBar,
} from '@/components';
import { faqQuestion } from '@/components/FAQ/faq.utils';
import { LazyLoaded } from '@/components/LazyLoaded';
import { DescriptionOfModal } from '@/components/SearchBar/parts';
import { useDepositsLayout } from '@/layouts/DepositsLayout/useDepositsLayout';
import { useAuthModalsControl, useAuthStore } from '@/store';
import type { IBankDepositCard } from '@/types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { analyticServices, cn, decodeBase64 } from '@/utils';

export interface IHomeProps {
  className?: string;
  topDeposits: IBankDepositCard[];
}

export const HomeLayout: FC<IHomeProps> = ({ topDeposits, className }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 728px)',
  });
  const router = useRouter();

  const { token, setEmail, setIsCodeLink, setVerificationCode } = useAuthStore(
    (state) => state,
  );

  const openLoginModal = useAuthModalsControl((state) => state.openLoginModal);
  const { syncUrlWithStore, updateUrlWithQuery } = useDepositsLayout();

  useEffect(() => {
    syncUrlWithStore();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const { code } = router.query;
      if (code && !token) {
        setIsCodeLink(true);

        const decodedCodeString = decodeBase64(code as string);

        if (!decodedCodeString) return;

        const decodedObj = JSON.parse(decodedCodeString);

        setVerificationCode(decodedObj.code);
        setEmail(decodedObj.email);

        openLoginModal();
      }
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleOnSubmit = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.clickFindDepositButton,
      category: AnalyticsCategory.main,
      label: 'clickFindDepositButton',
    });

    updateUrlWithQuery('/fixed-deposits');
  };

  return (
    <div className={cn('z-10 flex h-full flex-col rounded-lg', className)}>
      <div className="w-full bg-blue-100">
        <Hero />
      </div>
      <div className="md:shadow-far z-10 mx-auto -mt-12 w-full shrink-0 rounded-2xl px-5 py-6 sm:max-w-[500px] md:w-[660px] md:max-w-none md:bg-white md:p-6 lg:w-[888px] xl:w-[1140px]">
        <SearchBar
          isHomePage
          isScrolling={false}
          className="shadow-far"
          onSubmit={handleOnSubmit}
        />
        <div className="shadow-far z-10 mx-auto -mt-12 flex w-full flex-col gap-2 rounded-2xl bg-white py-6 md:hidden">
          {isMobile && (
            <LazyLoaded>
              <DescriptionOfModal buttonChange onSubmit={handleOnSubmit} />
            </LazyLoaded>
          )}
        </div>
        {/* TODO: For future use */}
        {/* <SearchExtend className="mt-2" /> */}
      </div>
      <div className="mt-7 flex w-full border-b">
        <Container className="z-[1]">
          <LogoClouds />
        </Container>
      </div>

      <Container className="z-[1] my-16 md:mb-[96px]">
        <HowItWorks />
      </Container>

      <div className="w-full xl:mb-5 xl:px-4">
        <div className="w-full bg-blue-50 pt-16 xl:rounded-2xl">
          <PickDeposits deposits={topDeposits} />
        </div>
      </div>

      <div className="w-full xl:px-4">
        <div className="w-full bg-blue-50 px-4 pb-8 pt-4 md:pb-[57px] lg:mb-4 lg:pb-[30px] xl:rounded-2xl xl:pt-[64px]">
          <FAQs
            questions={faqQuestion}
            blockClassName="body2 md:body1 lg:min-h-[96px]"
          />
        </div>
      </div>

      <NewsLetter />
    </div>
  );
};
