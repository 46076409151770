import { useUser } from '@supabase/auth-helpers-react';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import type { Dispatch, FC, SetStateAction } from 'react';
import React from 'react';

import { Icon } from '@/components';
import confirmEmailPng2x from '@/public/assets/pics/followLink@2x-min.png';
import confirmEmailWebp2x from '@/public/assets/pics/followLink@2x-min.webp';
import confirmEmailPng1x from '@/public/assets/pics/followLink-min.png';
import confirmEmailWebp1x from '@/public/assets/pics/followLink-min.webp';

interface IConfirmPopupContentProps {
  setConfirmPopup: Dispatch<SetStateAction<boolean>>;
}

export const ConfirmPopupContent: FC<IConfirmPopupContentProps> = ({
  setConfirmPopup,
}) => {
  const { t } = useTranslation();
  const user = useUser();
  return (
    <div className="flex flex-col items-center justify-center px-6 text-center">
      <picture>
        <source
          srcSet={`${confirmEmailWebp1x.src} 1x, ${confirmEmailWebp2x.src} 2x`}
          type="image/webp"
        />
        <source
          srcSet={`${confirmEmailPng1x.src} 1x, ${confirmEmailPng2x.src} 2x`}
          type="image/png"
        />
        <img
          src={confirmEmailPng1x.src}
          alt="confirmEmail"
          width={confirmEmailPng1x.width}
          height={confirmEmailPng1x.height}
        />
      </picture>

      <h3 className="my-4 font-bold tracking-wider">
        {t('deposit_card.leaving_site')}
      </h3>

      <p className="body1-bold mb-6 tracking-[0.1px]">
        {t('deposit_card.check_partner')}{' '}
        <Link href="/fixed-deposits" className="text-blue-500">
          {t('deposits.available_online').toLowerCase()}
        </Link>{' '}
        {!user && t('deposit_card.sign_up_info')}.
      </p>
      <div
        onClick={() => setConfirmPopup(false)}
        className="absolute right-6 top-6 cursor-pointer"
      >
        <Icon className="h-[32px] w-[32px]" name="closeIcon" />
      </div>
    </div>
  );
};
