import type { FC, ReactNode } from 'react';

import { cn } from '@/utils';

interface BlockWithTitleProps {
  title: string;
  children: ReactNode;
  className?: string;
  titleClassName?: string;
}
const BlockWithTitle: FC<BlockWithTitleProps> = ({
  children,
  title,
  className,
  titleClassName,
}) => {
  return (
    <div id={title} className={className}>
      <h2 className={cn('h4 mb-6 tracking-[0.2px]', titleClassName)}>
        {title}
      </h2>
      {children}
    </div>
  );
};

export default BlockWithTitle;
