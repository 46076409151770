import { Inter } from 'next/font/google';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Icon } from '@/components';
import type { IBlogSchema } from '@/types/blogInterfaces';
import { calculateReadTime, cn } from '@/utils';

import { formatDateToMonthDateYearFormat } from '../blog.utils';

const fontInter = Inter({ subsets: ['latin'] });

export const BlogCard = ({ item }: { item: IBlogSchema }) => {
  const { t } = useTranslation();

  const isTranding = false; // TODO: The isTranding property needs to be added from the API
  const readingTime = calculateReadTime([
    item.Title,
    item.Description,
    item.Body,
  ]);

  return (
    <div
      className={cn(
        'shadow-close transition-shadow hover:shadow-far relative flex max-w-full flex-col rounded-xl bg-white px-4 pb-4 pt-5 md:px-6 md:pb-3 md:pt-7',
        fontInter.className,
      )}
    >
      <div className="absolute right-6 top-1/2 hidden h-8 w-8 -translate-y-1/2 md:block">
        <Icon className="h-8 w-8 text-transparent" name="shevronRight" />
      </div>
      {isTranding && (
        <div className="absolute top-0 flex h-5 w-[66px] translate-y-[-50%] items-center justify-center rounded-full bg-blue-500 font-inter text-[11px] font-bold leading-[16px] tracking-[0.1px] text-white sm:h-7 sm:w-[82px] sm:text-[13px] sm:leading-[18px]">
          {t('global.trending')}
        </div>
      )}
      <div className="flex max-w-sm flex-col leading-tight">
        <div className="flex flex-col gap-4 text-start md:gap-6">
          <h5 className="w-[248px] text-[15px] font-bold leading-[20px] tracking-[0.2px] md:w-[512px] md:text-[20px] md:font-semibold md:leading-[24px]">
            {item.Title}
          </h5>

          <div className="flex items-center gap-2">
            <time className="body3 text-grey-800">
              {formatDateToMonthDateYearFormat(item.publishedAt)}
            </time>
            <div className="h-1 w-1 rounded-full bg-gray-300" />
            <p className="body3 text-grey-500">
              {readingTime}&nbsp;{t('blog.min_read')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
