import { useEffect, useMemo, useState } from 'react';

function useIsInViewport(ref: any) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => {
    if (typeof window === 'undefined') return null;

    return new IntersectionObserver(([entry]) =>
      entry ? setIsIntersecting(entry.isIntersecting) : null,
    );
  }, []);

  useEffect(() => {
    if (!observer) return;
    observer.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export default useIsInViewport;
