import type { Dispatch, FC, SetStateAction } from 'react';
import React from 'react';

import { Popup } from '@/components';
import { cn } from '@/utils';

import { ConfirmPopupContent, ConfirmPopupFooter } from './parts';

interface IConfirmPopupProps {
  source: string;
  setConfirmPopup: Dispatch<SetStateAction<boolean>>;
  confirmPopup: boolean;
}

export const ConfirmPopup: FC<IConfirmPopupProps> = ({
  source,
  setConfirmPopup,
  confirmPopup,
}) => {
  return (
    <Popup
      isOpen={confirmPopup}
      setOpen={(open) =>
        open ? setConfirmPopup(false) : setConfirmPopup(true)
      }
      className={cn(
        'w-full md:w-[604px] py-12 px-6 md:h-fit h-fit self-end md:self-center',
      )}
      footer={
        <ConfirmPopupFooter source={source} setConfirmPopup={setConfirmPopup} />
      }
    >
      <ConfirmPopupContent setConfirmPopup={setConfirmPopup} />
    </Popup>
  );
};
