import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { CURRENCY_SYMBOLS } from '@/store/storeSearch.consts';
import type { IBankDepositCard } from '@/types';
import {
  calcDepositTerms,
  checkIsNumber,
  cn,
  transformMonths,
  transformNumber,
} from '@/utils';

interface IDepositTermsProps {
  bank: IBankDepositCard;
}

export const DepositTerms: FC<IDepositTermsProps> = ({ bank }) => {
  const { t } = useTranslation();

  const { value, unit } = transformMonths(+bank.termMonths);

  const { displayAmount, prefix } = calcDepositTerms(
    bank.minimumAmount,
    bank.maximumAmount,
  );

  return (
    <div className="flex gap-3 md:justify-between md:gap-6">
      <div className="order-1 flex w-[194.5px] flex-col gap-y-[2px]">
        <p className="body3 text-start text-grey-600">
          {t('deposit_card.interest_rate')}
        </p>
        <p className="h1-inter font-semibold leading-[56px] text-blue-900">
          {Number(bank.grossInterestRate).toFixed(2)}%
        </p>
      </div>

      <div className="order-1 hidden w-[194.5px] flex-col gap-y-[2px] md:flex">
        <p className="body3 text-start text-grey-600">
          {t('deposit_card.term')}
        </p>
        {Number(value) !== 0 ? (
          <p className="body1 flex gap-1 font-semibold text-blue-900">
            <span>{value}</span>
            <span>{t(`deposit_card.${unit?.toLowerCase()}`)}</span>
          </p>
        ) : null}
      </div>

      <div
        className={cn(
          'w-[194.5px] flex-col gap-y-[2px] hidden md:flex order-1',
          !Number(bank.minimumAmount) && 'invisible order-2',
        )}
      >
        <p className="body3 text-start text-grey-600">
          {t('deposit_card.min_amount')}
        </p>
        <div className="body1 flex">
          <p className="body1 font-semibold text-blue-900">
            <span>{t(prefix)}&nbsp;</span>
            <span>
              {CURRENCY_SYMBOLS[bank.currency.toLowerCase()]}
              {transformNumber(bank.minimumAmount ? +bank.minimumAmount : 0)}
            </span>
          </p>
        </div>
      </div>

      <div
        className={cn(
          'w-[194.5px] flex-col gap-y-[2px] hidden md:flex order-1',
          !Number(bank.maximumAmount) && 'invisible',
        )}
      >
        <p className="body3 text-start text-grey-600">
          {t('deposit_card.max_amount')}
        </p>
        <div className="flex">
          <p className="body1 font-semibold text-blue-900">
            <span>{t(prefix)}&nbsp;</span>
            <span>
              {CURRENCY_SYMBOLS[bank.currency.toLowerCase()]}
              {transformNumber(bank.maximumAmount ? +bank.maximumAmount : 0)}
            </span>
          </p>
        </div>
      </div>

      <div className="order-1 flex w-[194.5px] flex-col gap-y-[9px] pt-6 md:hidden">
        <div className="body3 flex font-semibold text-blue-900">
          {Number(value) !== 0 ? (
            <p className="flex gap-1">
              <span>{value}</span>
              <span>{t(`deposit_card.${unit?.toLowerCase()}`)}</span>
            </p>
          ) : null}
        </div>
        {displayAmount && (
          <div className="flex gap-1">
            <p className="body3 font-semibold text-blue-900">
              {checkIsNumber(displayAmount) ? (
                <>
                  <span>{t(prefix)}&nbsp;</span>
                  <span>
                    {CURRENCY_SYMBOLS[bank.currency.toLowerCase()]}
                    {displayAmount}
                  </span>
                </>
              ) : (
                <span>{t(displayAmount)}</span>
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
